<template>
  <main>
    <package-header title="Edit Application" subTitle="12764 Smith"></package-header>

    <div class="container-fluid">
      <div class="row">
        <div class="col">

          <div class="mb-3 border-bottom pb-3">
            <!-- Back to applications link -->
            <router-link class="mr-2" to="/pages/applications">
              <svg class="icon icon-arrow_cta_back mr-sm-1"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_cta_back"></use></svg>
              <span class="d-none d-sm-inline-block">Return to Applications</span>
              <span class="d-sm-none">Back</span>
            </router-link>

            <!-- Change instrument dropdown -->
            <a href="javascript:void(0)" class="btn btn-md btn-secondary dropdown-toggle d-sm-inline-block" id="instrument" data-toggle="dropdown" aria-controls="instrument-contents" aria-expanded="false">
              Go To Application
              <span class="caret"></span>
            </a>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" href="javascript:void(0)">01 - Transfer Caso <span class="badge badge-success">E-signed</span></a>
              <a class="dropdown-item font-weight-bold" href="javascript:void(0)">02 - A1 release</a>
              <a class="dropdown-item" href="javascript:void(0)">03 - Mortgage Caso</a>
              <a class="dropdown-item" href="javascript:void(0)">04 - A2 release</a>
              <a class="dropdown-item" href="javascript:void(0)">05 - Application with longer name</a>
            </div>
          </div>

          <!-- Package title and actions -->
          <div class="d-md-flex align-items-start justify-content-between">
            <div>
              <h2 class="page-title mr-2">
                A1 release
              </h2>

              <ul class="list-inline mb-1">
                <li class="list-inline-item">
                  <small><span class="text-muted text-small">Status:</span> <strong>Drafted</strong></small>
                </li>
                <li class="list-inline-item">
                  <small><span class="text-muted">Modified:</span> <strong>Sep 22, 2018 at 5:33 PM</strong></small>
                </li>
              </ul>
            </div>

            <!-- Drawer toggle and delete package -->
            <div class="d-flex justify-content-between">
              <button class="btn btn-md btn-secondary d-md-none mb-2" type="button" data-toggle="collapse" data-target="#application-nav" aria-expanded="false" aria-controls="application-nav">
                <svg class="icon icon-more"><use xlink:href="/icons/symbol-defs.svg#icon-more"></use></svg>
                <svg class="icon icon-close"><use xlink:href="/icons/symbol-defs.svg#icon-close"></use></svg>
                Navigate Application
              </button>
              <button type="button" class="btn btn-md btn-danger mb-2">
                <svg class="icon icon-delete d-sm-none"><use xlink:href="/icons/symbol-defs.svg#icon-delete"></use></svg>
                <span class="d-none d-sm-block">Delete Application</span>
              </button>
            </div>
          </div>

          <!-- Main package content -->
          <div class="row mt-md-3">

            <!-- Step wizard -->
            <div class="col-md-3 mb-2 mb-md-4">
              <div class="collapse collapse-md" id="application-nav">
                <div class="step-wizard">
                  <div class="step">
                    <router-link class="step-link" to="/pages/edit-application/questionnaire">Transferees</router-link>
                    <div class="step active">
                      <router-link class="step-link" to="/pages/edit-application/part-a-individual">{Individual Transferee Name}</router-link>
                    </div>
                    <div class="step">
                      <router-link class="step-link" to="/pages/edit-application/part-a-corporation">{Corporate Transferee Name}</router-link>
                    </div>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Contact Info</a>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Transferors</a>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Property Description</a>
                  </div>
                  <div class="step">
                    <router-link class="step-link" to="/pages/edit-application/part-e-terms">Terms</router-link>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Gross Purchase Price</a>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Additional Info</a>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Principal Residence Info</a>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Tax Calculation</a>
                  </div>
                  <div class="step">
                    <router-link class="step-link" to="/pages/edit-application/first-time-home-buyer">First Time Home Buyers</router-link>
                  </div>
                  <div class="step">
                    <router-link class="step-link" to="/pages/edit-application/certification">Certification</router-link>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Attachments</a>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Validation</a>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Presentation Copy</a>
                  </div>
                </div>
              </div>
            </div>

            <!-- Data entry form -->
            <div class="col-md-9">

              <div class="d-flex justify-content-between align-items-center mb-2">
                <h2 class="mb-0">{Individual Transferee Name}</h2>
                <button class="btn btn-md btn-danger">Delete</button>
              </div>

              <!-- Transferee 1 -->
              <div class="mb-4 p-2 p-lg-4 bg-medium">
                <h3>Transferee Info</h3>

                <div class="card">
                  <div class="card-body">
                    Transferee type: <strong>Individual</strong><br>
                    Claiming BC Provincial Nominee (PN) Exemption: <strong>No</strong><br>
                    Canadian Citizen or Permanent Resident: <strong>Yes</strong><br>
                    Exemption Claimed: <strong>None - No exemption claimed</strong>
                  </div>
                </div>

                <form class="mt-4">
                  <fieldset class="border-bottom">
                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <label for="lastName">Last Name</label>
                        <input type="text" class="form-control" id="lastName">
                      </div>
                      <div class="form-group col-md-6">
                        <label for="givenName">Given Names</label>
                        <input type="text" class="form-control" id="givenName">
                      </div>
                    </div>

                    <div class="form-group">
                      <label for="percentInterest">
                        Percentage Interest Acquired
                        <a href="#" data-toggle="popover" data-placement="top" data-content="The interest acquired is only for the person gaining interest in the property. Any other person that is currently on title and their interest is remaining the same does not have to be added to the PTT return." v-popover>
                          <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
                        </a>
                      </label>
                      <div class="input-group">
                        <input type="text" class="form-control col-sm-4 col-md-3 border-right-0" id="inlineFormInputGroup">
                        <div class="input-group-append">
                          <div class="input-group-text">%</div>
                        </div>
                      </div>
                    </div>

                    <div class="form-group col-sm-5 col-md-3 px-0">
                      <label for="">Date of Birth</label>
                      <div class="input-group">
                        <svg class="icon icon-inline-right icon-date position-absolute"><use xlink:href="/icons/symbol-defs.svg#icon-date"></use></svg>
                        <input type="text" class="form-control">
                      </div>
                      <small class="form-text text-muted">YYYY-MM-DD</small>
                    </div>

                    <div class="form-row">
                      <div class="form-group col-sm-6">
                        <label for="sin">
                          Social Insurance Number
                          <a href="#" data-toggle="popover" data-placement="top" data-html="true" data-content="Social Insurance Number (SIN) is a 9 digit number that you need to work in Canada or to have access to government programs. A current SIN must be entered.  To obtain a new SIN or replacement please contact the <a href='https://www.canada.ca/en/employment-social-development/services/sin.html'  target='blank' rel='nofollow noopener'> Government of Canada</a> before proceeding with your transfer." v-popover>
                            <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
                          </a>
                        </label>
                        <input type="text" class="form-control" id="sin">
                      </div>
                      <div class="form-group col-sm-6">
                        <label for="taxNumber">
                          Individual Tax Number <small>(optional)</small>
                          <a href="#" data-toggle="popover" data-placement="top" data-html="true" data-content="Individual Tax Number (ITN) is a 9-digit number given to a purchaser who is not a Canadian citizen, but files taxes in Canada, and has applied for a <a href='https://www.canada.ca/en/revenue-agency/services/forms-publications/forms/t1261.html' target='blank' rel='nofollow noopener'> Canada Revenue Agency (CRA) ITN</a>." v-popover>
                            <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
                          </a>
                        </label>
                        <input type="text" class="form-control" id="taxNumber">
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="form-group col-sm-6">
                        <label for="certificate">
                          BC PN Certificate Number:
                          <a href="#" data-toggle="popover" data-placement="top" data-content="This is the nomination certificate number on the Confirmation of Nomination letter." v-popover>
                            <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
                          </a>
                        </label>
                        <input type="text" class="form-control" id="certificate">
                      </div>

                      <div class="form-group col-sm-6">
                        <label for="effectiveDate">BC PN Effective Date <small>(optional)</small></label>
                        <div class="input-group col-md-6 p-0">
                          <svg class="icon icon-inline-right icon-date position-absolute"><use xlink:href="/icons/symbol-defs.svg#icon-date"></use></svg>
                          <input type="text" id="effectiveDate" class="form-control">
                        </div>
                        <small class="form-text text-muted">YYYY-MM-DD</small>
                      </div>
                    </div>

                    <div class="form-group">
                      <label for="capacity">Country of Citizenship
                        <a href="#" data-toggle="popover" data-placement="top" data-content="Identify the foreign country or state of citizenship. If you have more than one foreign citizenship select all applicable." v-popover>
                          <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
                        </a>
                      </label>
                      <select class="form-control col-md-6" id="capacity">
                        <option value="other">multiselect</option>
                      </select>
                    </div>

                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <label for="phone">
                          Phone Number
                          <a href="#" data-toggle="popover" data-placement="top" data-content="If calling from outside of North America, enter your international country code." v-popover>
                            <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
                          </a>
                        </label>
                        <input class="form-control" type="tel" name="phone" id="phone" autocomplete="tel-national">
                      </div>
                      <div class="form-group col-md-6">
                        <label for="email">Email <small>(optional)</small></label>
                        <input class="form-control" type="email" name="email" id="email" autocapitalize="off" autocorrect="off" autocomplete="email">
                      </div>
                    </div>
                  </fieldset>

                  <fieldset class="border-bottom">
                    <legend>Current Address</legend>
                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <label for="address1">Address 1</label>
                        <input type="text" class="form-control" id="address1">
                        <small class="form-text text-muted">Street No./Name</small>
                      </div>
                      <div class="form-group col-md-6">
                        <label for="address2">Address 2 <small>(optional)</small></label>
                        <input type="text" class="form-control" id="address2">
                        <small class="form-text text-muted">Apt. No., PO Box, RR</small>
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <label for="city">City</label>
                        <input type="text" class="form-control" id="city">
                      </div>
                      <div class="form-group col-md-6">
                        <label for="country">Country</label>
                        <select class="form-control" id="country">
                          <option>Canada</option>
                          <option>United Kingdom</option>
                          <option>United States</option>
                          <option>Mexico</option>
                          <option>Other</option>
                        </select>
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="form-group col-sm-8 col-md-6">
                        <label for="province">Province</label>
                        <select class="form-control" id="province">
                          <option value="AB">Alberta</option>
                          <option value="BC" selected>British Columbia</option>
                          <option value="MB">Manitoba</option>
                          <option value="NB">New Brunswick</option>
                          <option value="NL">Newfoundland and Labrador</option>
                          <option value="NT">Northwest Territory</option>
                          <option value="NS">Nova Scotia</option>
                          <option value="NU">Nunavut</option>
                          <option value="ON">Ontario</option>
                          <option value="PE">Prince Edward Island</option>
                          <option value="QC">Quebec</option>
                          <option value="SK">Saskatchewan</option>
                          <option value="YT">Yukon Territory</option>
                        </select>
                      </div>
                      <div class="form-group col-sm-4 col-md-6">
                        <label for="postalCode">
                          Postal Code
                          <a href="#" data-toggle="popover" data-placement="top" data-content="If you do not know the postal code please use UNK for Canada or NCA otherwise." v-popover>
                            <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
                          </a>
                        </label>
                        <input class="form-control col-md-6" name="ship-zip" id="postalCode" placeholder="10011" autocomplete="shipping postal-code">
                      </div>
                    </div>
                  </fieldset>

                  <label class="mt-1">Did the transferee live in BC for one year prior to the transfer of the property?</label>
                  <div class="form-group mt-1">
                    <div class="custom-control custom-radio custom-control-inline">
                      <input type="radio" id="liveInBC" name="liveInBC" class="custom-control-input" @click="liveInBC = true">
                      <label class="custom-control-label" for="liveInBC">Yes</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                      <input type="radio" id="liveInBC2" name="liveInBC" class="custom-control-input" @click="liveInBC = false">
                      <label class="custom-control-label" for="liveInBC2">No</label>
                    </div>
                  </div>

                  <fieldset class="border-bottom" v-if="liveInBC">
                    <legend>Previous Address for Last Two Years</legend>
                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <label for="prevAddress1">Address 1</label>
                        <input type="text" class="form-control" id="prevAaddress1">
                        <small class="form-text text-muted">Street No./Name</small>
                      </div>
                      <div class="form-group col-md-6">
                        <label for="prevAddress2">Address 2 <small>(optional)</small></label>
                        <input type="text" class="form-control" id="prevAddress2">
                        <small class="form-text text-muted">Apt. No., PO Box, RR</small>
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <label for="prevCity">City</label>
                        <input type="text" class="form-control" id="prevCity">
                      </div>
                      <div class="form-group col-md-6">
                        <label for="prevcountry">Country</label>
                        <select class="form-control" id="prevcountry">
                          <option>Canada</option>
                          <option>United Kingdom</option>
                          <option>United States</option>
                          <option>Mexico</option>
                          <option>Other</option>
                        </select>
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <label for="prevprovince">Province</label>
                        <select class="form-control" id="prevprovince">
                          <option value="AB">Alberta</option>
                          <option value="BC" selected>British Columbia</option>
                          <option value="MB">Manitoba</option>
                          <option value="NB">New Brunswick</option>
                          <option value="NL">Newfoundland and Labrador</option>
                          <option value="NT">Northwest Territory</option>
                          <option value="NS">Nova Scotia</option>
                          <option value="NU">Nunavut</option>
                          <option value="ON">Ontario</option>
                          <option value="PE">Prince Edward Island</option>
                          <option value="QC">Quebec</option>
                          <option value="SK">Saskatchewan</option>
                          <option value="YT">Yukon Territory</option>
                        </select>
                      </div>
                      <div class="form-group col-md-6">
                        <label for="prevfrmZipS">
                          Postal Code
                          <a href="#" data-toggle="popover" data-placement="top" data-content="If you do not know the postal code please use UNK for Canada or NCA otherwise." v-popover>
                            <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
                          </a>
                        </label>
                        <input class="form-control" name="ship-zip" id="prevfrmZipS" placeholder="10011" autocomplete="shipping postal-code">
                      </div>
                    </div>

                    <div class="form-group">
                      <label for="yearsFiledTax">List any two years that the transferee filed income tax returns as a BC resident during the six years before the date of registration.</label>
                      <div class="form-row">
                        <div class="form-group mb-0 col-4 col-sm-3 col-lg-2">
                          <input type="text" class="form-control" id="taxYear1">
                          <div class="form-text text-muted">YYYY</div>
                        </div>
                        <div class="form-group mb-0 col-4 col-sm-3 col-lg-2">
                          <input type="text" class="form-control" id="taxYear2">
                          <div class="form-text text-muted">YYYY</div>
                        </div>
                      </div>
                    </div>
                  </fieldset>

                  <label>Does the transferee intend to use the transferred property as their principal residence?</label>
                  <div class="form-group mt-1">
                    <div class="custom-control custom-radio custom-control-inline">
                      <input type="radio" id="principal" name="principal" class="custom-control-input">
                      <label class="custom-control-label" for="principal">Yes</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                      <input type="radio" id="principal2" name="principal" class="custom-control-input">
                      <label class="custom-control-label" for="principal2">No</label>
                    </div>
                  </div>

                  <label class="mt-1">Is the transferee holding the property on behalf of a partnership?</label>
                  <div class="form-group mt-1">
                    <div class="custom-control custom-radio custom-control-inline">
                      <input type="radio" id="holdingProperty" name="holdingProperty" class="custom-control-input">
                      <label class="custom-control-label" for="holdingProperty">Yes</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                      <input type="radio" id="holdingProperty2" name="holdingProperty" class="custom-control-input">
                      <label class="custom-control-label" for="holdingProperty2">No</label>
                    </div>
                  </div>

                  <label class="mt-1">Is the transferee a trustee?</label>
                  <div class="form-group mt-1">
                    <div class="custom-control custom-radio custom-control-inline">
                      <input type="radio" id="trustee" name="trustee" class="custom-control-input" @click="showTrust = true">
                      <label class="custom-control-label" for="trustee">Yes</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                      <input type="radio" id="trustee2" name="trustee" class="custom-control-input" @click="showTrust = false">
                      <label class="custom-control-label" for="trustee2">No</label>
                    </div>
                  </div>
                </form>
              </div>

              <div class="mb-4" v-if="showTrust">
                <trust
                  :exemptFromDisclosure="trustExemptFromDisclosure" @onExemptFromDisclosure="trustExemptFromDisclosure = $event"
                ></trust>
              </div>
              <div class="mb-4" v-if="showTrust && trustExemptFromDisclosure === false">
                <settlors-beneficiaries :sectionId="1241"></settlors-beneficiaries>
              </div>

              <!-- Action buttons -->
              <div class="d-flex flex-column-reverse flex-sm-row justify-content-sm-between d-print-none">
                <div class="mt-2 mt-sm-0">
                  <button class="btn btn-secondary">Back</button>
                </div>
                <div class="d-flex justify-content-between">
                  <button class="btn btn-secondary mr-1">Save Progress</button>
                  <button class="btn btn-primary">Continue <svg class="icon icon-arrow_cta ml-1"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_cta"></use></svg></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Scroll to top -->
      <a href="#top" class="d-block float-right scroll-to-top">
        <svg class="icon icon-arrow_upward"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_upward"></use></svg>Back to Top
      </a>
    </div>

    <help-panel title="Applications"></help-panel>
  </main>
</template>

<script>

import Trust from './shared/Trust.vue'
import SettlorsBeneficiaries from './shared/SettlorsBeneficiaries.vue'
import PackageHeader from '../../../../components/PackageHeader.vue'
import PackageNavigation from '../../../../components/PackageNavigation.vue'
import HelpPanel from '../../../../components/HelpPanel.vue'
import DatePicker from '../../../../components/DatePicker'

export default {

  components: {
    'trust': Trust,
    'settlors-beneficiaries': SettlorsBeneficiaries,
    'package-header': PackageHeader,
    'package-navigation': PackageNavigation,
    'help-panel': HelpPanel,
  },

  data () {
    return {
      liveInBC: false,
      hasCorporateInterestHolders: false,
      showTrust: false,
      excludedFromDisclosure: false,
      trustExemptFromDisclosure: null
    }
  },
  methods: {

  }
}
</script>

